import "./app.css";
import Count from "./containers/Count";
import Person from "./containers/Person";

export default function App() {
  return (
    <div className="container">
      <Count />
      <Person />
    </div>
  );
}
