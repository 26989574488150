import React, { Component } from "react";
import "./index.css";

import {
  incrementAction,
  decrementAction,
  incrementAsyncAction,
} from "../../redux/actions/count";

import { connect } from "react-redux";

class Count extends Component {
  increment = () => {
    const { value } = this.selectEle;
    this.props.increment(value * 1);
  };
  decrement = () => {
    const { value } = this.selectEle;
    this.props.decrement(value * 1);
  };
  incrementIfOdd = () => {
    const { value } = this.selectEle;
    if (this.props.count % 2 !== 0) {
      this.props.increment(value * 1);
    }
  };
  incrementAsync = () => {
    const { value } = this.selectEle;
    this.props.incrementAsync(value * 1, 1000);
  };
  render() {
    return (
      <div className="countBox">
        <h1>Redux 是 JavaScript 应用的状态容器，提供可预测的状态管理。</h1>
        <h3>当前计算结果为：{this.props.count}，下方组件总人数为：{this.props.totalPerson}</h3>
        <div>
          <select ref={(el) => (this.selectEle = el)}>
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
          </select>
          &nbsp;
          <button onClick={this.increment}>加法</button>&nbsp;
          <button onClick={this.decrement}>减法</button>&nbsp;
          <button onClick={this.incrementIfOdd}>基数再加</button>&nbsp;
          <button onClick={this.incrementAsync}>异步加</button>
        </div>
      </div>
    );
  }
}


export default connect(state=>({ count: state.count, totalPerson: state.person.length }), {
  increment: incrementAction,
  decrement: decrementAction,
  incrementAsync: incrementAsyncAction,
})(Count);
