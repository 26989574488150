//引入react核心库
import React from "react";

// 引入react-dom，用于支持react操作DOM
// import ReactDOM from "react-dom";
import { createRoot } from "react-dom/client";

// 引入主组件
import App from "./App";

// BrowserRouter
import { BrowserRouter } from "react-router-dom";

// store
import store from "./redux/store";
import {Provider} from "react-redux";

// 获取容器
const root = createRoot(document.getElementById("root"));

// 渲染组件到页面中
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Provider store={store}>
        <App />
      </Provider>
    </BrowserRouter>
  </React.StrictMode>
);
