import React, { Component } from 'react';
import { nanoid } from 'nanoid';
import {
  addPersonAction
} from "../../redux/actions/person";

import { connect } from "react-redux";
import "./index.css"

class Person extends Component {
  addPerson = ()=>{
    const name = this.nameEl.value;
    const age = this.ageEl.value;
    const person = {
      id: nanoid(),
      name,
      age
    }
    this.props.addPerson(person)
  }
  render() {
    return (
      <div className="personBox">
        <hr />
        <h1>我是Person组件，上方组件求和为{this.props.count}</h1>
        姓名：<input ref={el=>this.nameEl = el} /><br/>
        年龄：<input ref={el=>this.ageEl = el} /><br/>
        <button onClick={this.addPerson}>添加用户</button>
        <ul>
          {this.props.persons.map(item=><li key={item.id}>{item.name}---{item.age}</li>)}
          <li>姓名111---年龄222</li>
        </ul>
      </div>
    )
  }
}

export default connect(state=>({persons: state.person, count: state.count}),{addPerson:addPersonAction})(Person)
